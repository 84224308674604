import BuildInformation from '@aurora/shared-client/components/common/BuildInformation/BuildInformation';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import ThemedContainer from '@aurora/shared-client/components/common/ThemedContainer/ThemedContainer';
import useCommunityCookieProperties from '@aurora/shared-client/components/community/useCommunityCookieProperties';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import type { PageAndEmptyParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import { canUseDOM } from 'exenv';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { WidgetEditLevel } from '../../../helpers/quilt/PageEditorHelper';
import EditableWidget from '../../common/Widget/EditableWidget';
import type { WidgetFC } from '../../common/Widget/types';
import useTranslation from '../../useTranslation';
import localStyles from './FooterWidget.module.pcss';
import useCommunityAccess from '../../authentication/AuthenticationLink/useCommunityAccess';
import { focusFirstTabbableChildElement } from '@aurora/shared-client/helpers/ui/KeyboardNavigationHelper/KeyboardNavigationHelper';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import type { FooterWidgetProps } from './types';
import type { CSSPropertiesWithVars } from '@aurora/shared-client/helpers/styles/CSSPropertiesWithVarsHelper';
import useSafeColor from '@aurora/shared-client/components/useSafeColor';
import { LinkTarget } from '@aurora/shared-generated/types/graphql-schema-types';

const KhorosLogo = dynamic(() => import('../KhorosLogo/KhorosLogo'));
const FooterWidgetHelpLink = dynamic(() => import('../FooterWidgetHelpLink/FooterWidgetHelpLink'));

interface Props extends FooterWidgetProps {
  /**
   * Whether to show the Home Link and the navigation for the top page.
   */
  useHomeAndTopLink?: boolean;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

export const defaultFooterWidgetProps: FooterWidgetProps = {
  linkTextColor: CommonColorCssVariables.GRAY_700,
  linkTextHoverColor: CommonColorCssVariables.GRAY_600,
  helpLink: null,
  helpLinkOpenInNewTab: false,
  backgroundColor: CommonColorCssVariables.TRANSPARENT,
  backgroundOpacity: 100
};

function scrollToTop(): void {
  if (canUseDOM) {
    window.scrollTo(0, 0);
    focusFirstTabbableChildElement(document.body);
  }
}

/**
 * Footer Widget with Khoros Logo
 *
 * @author Adam Ayres, Willi Hyde
 */
const FooterWidget: WidgetFC<Props> = props => {
  const { canAccessCommunity } = useCommunityAccess();
  const {
    publicConfig: { poweredByLogoEnabled }
  } = useContext(TenantContext);
  const finalProps = { useHomeAndTopLink: true, ...props };
  const {
    useHomeAndTopLink,
    className,
    linkTextColor = defaultFooterWidgetProps.linkTextColor,
    linkTextHoverColor = defaultFooterWidgetProps.linkTextHoverColor,
    helpLink = defaultFooterWidgetProps.helpLink,
    helpLinkOpenInNewTab = defaultFooterWidgetProps.helpLinkOpenInNewTab,
    backgroundColor = defaultFooterWidgetProps.backgroundColor,
    backgroundOpacity = defaultFooterWidgetProps.backgroundOpacity
  } = finalProps;
  const { Link } = useEndUserRoutes();
  const cx = useClassNameMapper(localStyles);
  const { data, loading } = useCommunityCookieProperties(module);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.FOOTER_WIDGET);
  const safeColor = useSafeColor();
  const helpLinkTarget = helpLinkOpenInNewTab ? LinkTarget.Blank : LinkTarget.Self;

  finalProps.editLevel = WidgetEditLevel.FULL;

  if (textLoading || loading) {
    return null;
  }

  const { cookiePrivacyPolicyUrl } = data?.community?.cookieProperties ?? {};

  const footerStyles: CSSPropertiesWithVars = {
    '--lia-local-link-color': linkTextColor,
    '--lia-local-link-hover-color': linkTextHoverColor,
    '--lia-local-bg-color':
      backgroundColor === CommonColorCssVariables.TRANSPARENT
        ? CommonColorCssVariables.TRANSPARENT
        : safeColor(backgroundColor)
            .alpha(backgroundOpacity / Math.pow(10, 2))
            .toString()
  };

  return (
    <EditableWidget<Props> props={finalProps}>
      <div className={cx('lia-footer', className)} style={footerStyles}>
        <ThemedContainer
          data-testid="FooterWidget.Link"
          className={cx('lia-container')}
          ignoreXsGutters
        >
          <div className={cx('lia-footer-links')}>
            {useHomeAndTopLink && (
              <>
                <Link<PageAndEmptyParams>
                  route={EndUserPages.CommunityPage}
                  passHref
                  legacyBehavior={true}
                >
                  <a className={cx('lia-link')}>{formatMessage('homeLink')}</a>
                </Link>
                <Button
                  data-testid="FooterWidget.Button"
                  onClick={scrollToTop}
                  variant={ButtonVariant.UNSTYLED}
                  className={cx('lia-link')}
                >
                  {formatMessage('topOfPage')}
                </Button>
              </>
            )}
            {cookiePrivacyPolicyUrl && (
              <a
                className={cx('lia-link')}
                href={cookiePrivacyPolicyUrl}
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage('privacyPolicy')}
              </a>
            )}
            {canAccessCommunity && (
              <FooterWidgetHelpLink
                linkText={formatMessage('helpLink')}
                linkUrl={helpLink}
                linkTarget={helpLinkTarget}
                className={cx('lia-link')}
              />
            )}
          </div>
          <BuildInformation />
          {poweredByLogoEnabled && (
            <KhorosLogo className={cx('lia-logo-wrap')} svgClassName={cx('lia-logo')} />
          )}
        </ThemedContainer>
      </div>
    </EditableWidget>
  );
};

export default FooterWidget;
