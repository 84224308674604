import React from 'react';
import type { PageAndEmptyParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { LinkTarget } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import { useClassNameMapper } from 'react-bootstrap';
import { calculateTarget } from '@aurora/shared-client/helpers/links/LinkTargetHelper';
import ExternalLink from '@aurora/shared-client/components/common/ExternalLink/ExternalLink';

interface Props {
  /**
   * The text to display for the help link.
   */
  linkText: string;
  /**
   * The URL for the help link.
   */
  linkUrl?: string;
  /**
   * The target for the help link.
   */
  linkTarget?: LinkTarget;
  /**
   * Class name(s) to apply to the link.
   */
  className?: string;
}

/**
 * Footer Widget help link.
 *
 * @author Willi Hyde
 */
const FooterWidgetHelpLink: React.FC<React.PropsWithChildren<Props>> = ({
  linkText,
  linkUrl,
  linkTarget = LinkTarget.Self,
  className
}) => {
  const { Link } = useEndUserRoutes();
  const cx = useClassNameMapper();

  /**
   * If the link URL is not set, we will link to the default help page.
   */
  if (!linkUrl) {
    return (
      <Link<PageAndEmptyParams> route={EndUserPages.HelpFAQPage} passHref legacyBehavior={true}>
        <a
          target={calculateTarget(linkTarget)}
          className={cx(className)}
          data-testid="FooterWidgetHelpLink.Internal.Link"
        >
          {linkText}
        </a>
      </Link>
    );
  }

  /**
   * If the link URL is set, we will link to the provided URL.
   */
  return (
    <ExternalLink
      href={linkUrl}
      target={linkTarget}
      className={cx(className)}
      testId="FooterWidgetHelpLink.External.Link"
    >
      {linkText}
    </ExternalLink>
  );
};

export default FooterWidgetHelpLink;
